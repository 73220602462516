.navbar-wrapper {
  width: 90%;
  margin: 0 auto;

  overflow-x: hidden !important;
}

.navbar-wrapper .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.navbar-nav {
  position: relative;
  z-index: 3;
  overflow-x: hidden !important;

  clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);
  /* padding: 15px 80px; */

  overflow: hidden;
  /* padding: 31px 150px; */
}

/* .navClick .nav-item a.nav-link.active {
  border-bottom: 2px solid red;
} */

.navClick a {
  border-bottom: 2px solid red;
}

button.btn.btn-dark.shadow-none.connect-wallet {
  padding: 14px 33px;
  /* background: #ffc810; */
  margin-left: -39px;
  clip-path: polygon(22% 0, 100% 0%, 100% 100%, 0 99%);
  font-family: "Glaive";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  color: #ffffff;
}

.navbar-nav2 {
  background: url(../../../../public/assets/images/header/Group\ 1614.png);
  background-size: 100% 100%;
}

.connect-wallet2 {
  background-color: #ffc810 !important;
  color: #000 !important;
}

/* .navbar-nav li.nav-item {
  padding: 0px 6px;
} */

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background-color: white;
}

/* .navbar-nav .nav-item .nav-link {
  padding: 15px;
  position: relative;
} */

/* .navbar-nav .nav-item .nav-link:hover {
  background: linear-gradient(
    270deg,
    #95c11f 2.29%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 25.5px 25.5px 0px;
} */

/* .active {
  background: linear-gradient(
    270deg,
    #95c11f 2.29%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 25.5px 25.5px 0px;
  border: 2px solid
    linear-gradient(270deg, #95c11f 2.29%, rgba(255, 255, 255, 0) 100%);
  border-left: none;
} */

.navbar-nav li a {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;

  color: #ffffff;
}

/* .logo-wrapper {
  width: 220px;
  height: 104px;
  margin-top: -12px;
} */

.navbar-expand-lg .navbar-collapse {
  justify-content: end !important;
}

@media screen and (max-width: 992px) {
  .navbar-nav {
    background: rgba(0, 0, 0, 0.9) !important;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
  }
}

@media screen and (max-width: 1921px) {
  /* .logo-wrapper {
    width: 280px;
  } */
  .navbar-nav li a {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    color: #ffffff;
  }
  .navbar-nav li.nav-item {
    padding: 0px 10px;
  }

  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);

    clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);
    padding-top: 20px !important;
    padding-left: 90px !important;
    padding-bottom: 17px !important;
    padding-right: 90px !important;

    overflow: hidden;

    /* padding: 31px 150px; */
  }
  button.btn.btn-dark.shadow-none.connect-wallet {
    padding: 18px 40px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    clip-path: polygon(22% 0, 100% 0%, 100% 100%, 0 99%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
    border: none;
    outline: none;
    position: relative;
  }

  .navbar-nav2 {
    background: url(../../../../public/assets/images/header/Group\ 1614.png);
    background-size: 100% 100%;
  }

  .connect-wallet2 {
    background-color: #ffc810;
  }
}

@media screen and (max-width: 1441px) {
  .navbar-wrapper .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);
    /* padding: 15px 80px; */
    /* padding-top: 14px;
    padding-left: 70px;
    padding-bottom: 17px;
    padding-right: 70px; */
    overflow: hidden;
    /* padding: 31px 150px; */
  }

  button.btn.btn-dark.shadow-none.connect-wallet {
    padding: 14px 33px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    clip-path: polygon(22% 0, 100% 0%, 100% 100%, 0 99%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
  }
  .navbar-nav2 {
    background: url(../../../../public/assets/images/header/Group\ 1614.png);
    background-size: 100% 100%;
  }

  .connect-wallet2 {
    background-color: #ffc810;
  }
}

@media screen and (max-width: 1369px) {
  button.btn.btn-dark.shadow-none.connect-wallet {
    padding: 10px 35px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    clip-path: polygon(21% 0, 100% 0%, 100% 100%, 0 99%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);

    /* padding-top: 8px;
    padding-left: 70px;
    padding-bottom: 11px;
    padding-right: 70px; */
    overflow: hidden;
  }
  .navbar-nav li.nav-item {
    padding: 0px 3px;
  }
  .navbar-nav2 {
    background: url(../../../../public/assets/images/header/Group\ 1614.png);
    background-size: 100% 100%;
  }

  .connect-wallet2 {
    background-color: #ffc810;
  }
}

@media screen and (max-width: 1025px) {
  button.btn.btn-dark.shadow-none.connect-wallet {
    padding: 10px 35px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    /* clip-path: polygon(21% 0, 100% 0%, 100% 100%, 0 99%); */
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);
    /* clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%); */
    /* padding-top: 8px;
    padding-left: 30px;
    padding-bottom: 11px;
    padding-right: 30px; */
    overflow: hidden;
  }
  .navbar-nav li.nav-item {
    padding: 0px 3px;
  }
  .navbar-nav2 {
    background: url(../../../../public/assets/images/header/Group\ 1614.png);
    background-size: 100% 100%;
  }

  .connect-wallet2 {
    background-color: #ffc810;
  }
}

@media screen and (max-width: 768px) {
  .navbar-nav .nav-item .nav-link:hover {
    border: none;
  }

  .navbar-nav {
    background-color: black;
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    padding: 15px;
  }
  button.navbar-toggler.d-flex.d-lg-none.flex-column.justify-content-around {
    position: relative;
    z-index: 9999;
    top: 28px;
  }
  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);

    overflow: hidden;
  }

  button.btn.btn-dark.shadow-none.connect-wallet {
    /* padding: 10px 82px; */
    margin-left: rgba(0, 0, 0, 0.9);
    margin-left: 0px;
    clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0 100%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    width: 100%;
    /* text-align: left; */
    position: relative;
    z-index: 9999999999999;
  }

  .header-wrapper {
    width: 100%;
    height: 60vh;
    position: relative;
    overflow: hidden !important;
  }
  .header-man-bg img {
    width: 100%;
    animation: header-man-bg 5s ease-in-out 0s infinite normal forwards;
  }
  .about-wrapper .about-wrapper-r-side {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 380px) {
  /* .logo-wrapper {
    width: 232px;
  } */
}

@media screen and (max-width: 359px) {
  /* .logo-wrapper {
    width: 200px;
  } */
}
