* {
  backdrop-filter: none !important;
  /* outline: 2px solid lime; */
}
.list-for-sale {
  background: black;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 auto;
}
