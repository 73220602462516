.navbar-wrapper {
  width: 90%;
  margin: 0 auto;

  overflow-x: hidden !important;
}

.navbar-wrapper .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.navbar-nav {
  position: relative;
  z-index: 3;
  overflow-x: hidden !important;
  background: rgba(0, 0, 0, 0.9);
  clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);
  /* padding: 15px 80px; */
  padding-top: 14px;
  padding-left: 20px;
  padding-bottom: 17px;
  padding-right: 20px;
  overflow: hidden;
  /* padding: 31px 150px; */
}

.RainbowBtnFix {
  padding-right: 0px !important;
  padding-left: 50px !important;
  padding-block: 0px !important;
  margin-bottom: -10px;
  box-shadow: none;
  cursor: default !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
}
.RainbowBtnFix * {
  color: white !important;
  background: none !important;
}

.RainbowBtnFix > div > button:nth-child(1) * {
  border: none !important;
}

.RainbowBtnFix button {
  padding-inline: 0px !important;
  background: none !important;
  padding: 14px 33px !important;
  margin-right: -10px !important;
  margin-left: -39px !important;
  height: 65px !important;

  clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 99%) !important;
}

/* .navClick a {
  border-bottom: 2px solid red;
} */

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background-color: white;
}

/* .navbar-nav .nav-item .nav-link {
  padding: 15px;
  position: relative;
} */

/* .navbar-nav .nav-item .nav-link:hover {
  background: linear-gradient(
    270deg,
    #95c11f 2.29%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 25.5px 25.5px 0px;
} */

/* .active {
  background: linear-gradient(
    270deg,
    #95c11f 2.29%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 25.5px 25.5px 0px;
  border: 2px solid
    linear-gradient(270deg, #95c11f 2.29%, rgba(255, 255, 255, 0) 100%);
  border-left: none;
} */

.navbar-nav li a {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;

  color: #ffffff;
}

.logo-wrapper {
  width: fit-content;
  /* height: 104px; */
  margin-top: -12px;
}

.logo-wrapper img {
  padding-top: 10px;
  /* transform: scale(0.8); */
  width: 90px;
}

.rainbowBtn.btn.btn-dark.shadow-none.connect-wallet {
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .navbar-nav .nav-item .nav-link:hover {
    border: none;
  }

  .navbar-nav {
    background-color: black;
    width: 100%;
    position: relative;
    border-radius: 5px;
    padding: 15px;
  }
  button.navbar-toggler.d-flex.d-lg-none.flex-column.justify-content-around {
    position: relative;
    z-index: 9999;
  }
}

@media screen and (max-width: 992px) {
  .rainbowBtn.btn.btn-dark.shadow-none.connect-wallet {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
    width: 100% !important;
    margin: 0px !important;
  }

  .navbar-toggler {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1921px) {
  .logo-wrapper {
    /* width: 280px; */
  }
  .navbar-nav li a {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    color: #ffffff;
  }
  .navbar-nav li.nav-item {
    padding: 0px 10px;
  }

  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);

    clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);
    padding-top: 20px;
    padding-left: 90px;
    padding-bottom: 17px;
    padding-right: 90px;

    overflow: hidden;

    /* padding: 31px 150px; */
  }

  .rainbowBtn.btn.btn-dark.shadow-none.connect-wallet {
    padding: 18px 40px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 99%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
  }
}

@media screen and (max-width: 1441px) {
  .navbar-wrapper .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0rem;
    padding-bottom: 0rem;
    z-index: 5 !important;
  }

  .navbar-toggler {
    margin-top: 20px !important;
  }

  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);
    /* padding: 15px 80px; */
    padding-top: 14px;
    padding-left: 70px;
    padding-bottom: 17px;
    padding-right: 70px;
    overflow: hidden;
    /* padding: 31px 150px; */
  }

  .rainbowBtn.btn.btn-dark.shadow-none.connect-wallet {
    padding: 14px 33px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 99%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
  }

  .RainbowBtnFix {
    padding-block: 0px !important;
    margin-bottom: -16px;
  }

  .RainbowBtnFix > div > button {
    padding: 0px 13px !important;
  }
}

@media screen and (max-width: 1369px) {
  .rainbowBtn.btn.btn-dark.shadow-none.connect-wallet {
    padding: 10px 35px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 99%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%);

    /* padding-top: 8px; */
    padding-left: 70px;
    padding-bottom: 11px;
    padding-right: 70px;
    overflow: hidden;
  }
  .navbar-nav li.nav-item {
    padding: 0px 3px;
  }
}

@media screen and (max-width: 1025px) {
  .rainbowBtn.btn.btn-dark.shadow-none.connect-wallet {
    padding: 10px 35px;
    background: rgba(0, 0, 0, 0.9);
    margin-left: -39px;
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 99%);
    font-family: "Glaive";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .navbar-nav {
    position: relative;
    overflow-x: hidden !important;
    background: rgba(0, 0, 0, 0.9);
    /* clip-path: polygon(0 0, 100% 0%, 90% 100%, 10% 100%); */
    /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important; */

    padding-top: 8px;
    padding-left: 30px;
    padding-bottom: 11px;
    padding-right: 30px;

    margin-inline: 0px !important ;
    overflow: hidden;
  }
  .navbar-nav li.nav-item {
    padding: 0px 3px;
  }
}

.pointer {
  cursor: pointer !important;
}
